/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "normalize.css"
import "typeface-inter"

export const onInitialClientRender = () => {
    document.body.classList.add("is-loaded");
}